<template>
	<div>
		<div>
			<headerTop></headerTop>
		</div>
		<div>
			<headerMin :searchState="searchState"></headerMin>
		</div>
		<div class="doctor_content wrap">
			<div class="indexes"> <span @click="$router.push('/')"> 首页 </span> > 线上问诊</div>
			<!-- 		<div class="navList">
				<div class="navList1">
					<div class="title">
						选择科室
						<i></i>
					</div>
					<div class="conent"  :class="{open:open}">
						<div @click="navTab1(v,i)" :class="{navActive1:arrList1Class == i}" class="tab" v-for="(v,i) in arrList1" :key="i">{{v.category_name}}</div>
					</div>
					<div class="tab" @click="open=!open">
						{{open==true?"展开":"收起" }}
						<img src="@/assets/image/Listofpeople_Open_icon@2x.png" alt />
					</div>
				</div>
				<div class="navList1">
					<div>
						二级科室
						<i></i>
					</div>
					<div class="conent" :class="{open:open2}">
						<div @click="navTab2(v,i)" :class="{navActive1:arrList2Class == i}" class="tab" v-for="(v,i) in arrList2" :key="i">{{v.category_name}}</div>
					</div>
					<div class="tab"  @click="open2=!open2">{{open2==true?"展开":"收起" }}
						<img src="@/assets/image/Listofpeople_Open_icon@2x.png" alt />
						</div>
				</div>
			</div> -->
			<!-- 背景 -->
			<div class="navListbg"></div>
			<!-- 医生选择 -->
			<div class="selectTab">
				<!-- 列表 -->
				<div v-for="(v,i) in doctorList" :key="i" @click="GoDoctorDetails(v)" class="box">
					<div v-if="v.is_duty == 0" class="sum sumNum">未在线</div>
					<div v-else-if="v.waiting_num == 0" class="sum">空闲</div>
					<div v-else-if="v.waiting_num != 0" class="sum sumNum">{{v.waiting_num}}人排队</div>
					<div class="headerImg">
						<img v-if="v.avatar != '' && v.avatar != null && !v.isDefaultImg" :src="v.avatar" @error="v.isDefaultImg = true" />
						<img v-else src="@/assets/image/Listofpeople_Headportrait_img@2x.png" alt />
					</div>
					<p class="p1">
						<span>{{v.realname}}</span>
						{{v.job_name}}
					</p>
					<p class="p2 two">{{v.category_name}}</p>
					<p class="p3 two">{{v.zizhi_desc}}</p>
					<div class="bottom" :class="{isfree:v.is_duty != 0?(v.waiting_num==0?true:false):false}">
						<div class="bottom-btn">
							<img class="fl" src="@/assets/image/Listofpeople_picture_icon@2x.png" alt />
							<p class="txt1">图文问诊</p>
						</div>
					</div>
				</div>
			</div>
			<div class="fenye" v-if="showPage">
				<el-pagination @current-change="currentChanage" background layout="prev, pager, next" :page-size="16" :total="total"></el-pagination>
			</div>
		</div>
		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
	</div>
</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom.vue";
	import {
		getOnlineList
	} from "@/request/index"; // 获取在线医生列表
	import {
		getCategoryList
	} from "@/request/index"; //科室 列表

	export default {
		components: {
			headerTop,
			headerMin,
			publicBottom
		},
		props: [],
		data() {
			return {
				searchState: 0,
				search: "",
				arrList1: [], // 1级科室 列表
				arrList1Class: 0, // 1级科室 的样式
				arrList2Class: -1, // 2级科室 的样式
				arrList2: [], // 2级 科室 的列表
				doctorList: [], // 医生列表
				websock: null,
				path: "http://hlwyaodian.jzbwlkj.com",
				socket: "",
				showPage: false,
				total: 0,
				open: true,
				open2: true,
			};
		},
		created() {
			this.start();
		},
		methods: {
			start() {
				//  科室分类
				getCategoryList({}).then(res => {
					this.arrList1 = res.data;
				});
				// 医生 列表
				this.getPageDoctorList(1, 0);
			},
			//  1级 科室的 点击
			navTab1(e, i) {
				// 点击 列表 获取二级科室
				this.arrList1Class = i;
				this.arrList2Class = -1;
				this.cert_id = e.id;
				getCategoryList({
					pid: e.id
				}).then(res => {
					this.arrList2 = res.data;
				});
				//  点击列表 获取 医生
				this.getPageDoctorList(1, e.id);
			},
			//  2级 科室 的 点击
			navTab2(e, i) {
				// 点击 列表 获取二级科室
				this.arrList2Class = i;
				this.cert_id =
					//  点击列表 获取 医生
					this.getPageDoctorList(1, e.id);
			},
			GoDoctorDetails(v) {
				this.$router.push({
					path: "/doctor/doctorDetails?id=" + v.id + "&uid=" + v.uid + "&waiting_num=" + v.waiting_num
				});
			},
			GoAllGoods() {
				this.$router.replace({
					path: "/allGoods"
				});
			},
			getPageDoctorList(page, category_id = 0) {
				getOnlineList({
					token: this.$token,
					cert_type: 1,
					page_size: 16,
					category_id,
					page
				}).then(res => {
					res.data.data.forEach(item => item.isDefaultImg = false)
					this.total = res.data.total
					this.showPage = res.data.last_page !== 1
					this.doctorList = res.data.data;
				});
			},
			currentChanage(page) {
				this.getPageDoctorList(page, this.cert_id);
			}
		}
	};
</script>
<style lang="less" scoped>
	@import "./doctor";

	.open {
		white-space: nowrap;
	}

	.navList1 {
		.title {}

		.conent {
			flex: 8;
			float: left;
			overflow: hidden;
			text-overflow: ellipsis;

			.tab {

				width: auto !important;
				display: inline-block;
			}
		}
	}
</style>
